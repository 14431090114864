import React, {useState, useEffect} from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';
// import axios from 'axios'
import ReqCur from '../components/reqCur'

import PartnersLogos from '../components/partnerLogos.js'

import UXlogos from "../components/uxLogos.js"

import InternationalStudents from '../components/internationalStudents.js'
import Instalments from '../components/instalments.js'
import UXProjectsExamples from '../components/UXProjectsExamples.js'

// import {StripeProvider} from 'react-stripe-elements';
// import {Stripe} from 'stripe.js'
// import Checkout from '../components/checkout';

import Contacts from '../components/contactform';
import Covid from '../components/covid';

import UXBanner from '../pics/ux_design_online_bootcamp.png'

import Marina from '../pics/team/marina_tudela.jpg'
import Margarita from '../pics/team/margarita_barcelona_code_school.jpg'

import Mateo from '../pics/students/mateo.png'
import Chris from '../pics/students/chris.png'
import Brett from '../pics/students/brett.png'


import coursicab from '../pics/partners/coursicab.png'


let UXBootcamp =()=> {	
	let [weeks,setWeeks]=useState({
		week1:'none',
		week2:'none',
		week3:'none',
		week4:'none',
		week5:'none',
		week6:'none',
		week7:'none',
		week8:'none',
		week9:'none',
	})


	let showFull=(event)=>{
		weeks[event.target.title] === 'block' ? setWeeks({...weeks, [event.target.title]: 'none' }) : setWeeks({...weeks, [event.target.title]: 'block'})
	}

	// let [courses, setCourses] = useState([])

	// useEffect(()=>{
	// 	let getData = async () => {
	// 		let data = await axios.get('https://barcelonacodeschool.com/events/UXbootcamp')
	// 		setCourses(data.data)
	// 		console.log(data.data)
	// 	}
	// 	getData()
	// },[])
	let course = 
	{
		durationdays: 61,
		durationweeks: 9,
		fulldesc1: "Self-paced, online, mentored, in English",
		fulldesc2: "Registration and tuition payment 2900€.",
		fullprice: 2900,
		image: UXBanner,
		priceoptions: ['2900'],
		shortdesc: "Learn UX Design process on a real project",
		signupprice: 2900,
		tag: "UXbootcamp",
		title: "UX/UI Design Online Mentored Bootcamp"
	}
	




	return <div className="flexContent">
	<Helmet
	title="UX/UI Design Online Mentored Bootcamp in Barcelona Code School"
	meta={[
		{ name: 'description', content: 'Competence-based part-time UX/UI Design Online Mentored Bootcamp in Barcelona Code School. ' },
		{ name: 'keywords', content: 'ux design bootcamp, ux bootcamp, design bootcamp in barcelona, ux ui design bootcamp in barcelona, ui bootcamp, ux course in barcelona, ux workshop in barcelona, learn ux design, learn Sketch, learn Adobe XD, learn InVision, learn InVision Studio, learn Flinto' },
		{property: "og:title", content:"UX/UI Design Online Mentored Bootcamp in Barcelona Code School"},
		{property: "og:description", content: "UX/UI Design Online Mentored Bootcamp in Barcelona Code School. Part-time remote and self-paced. Learn UX Design mentored by our instructors."},
		{property: "og:image", content: "https://barcelonacodeschool.com/files/pics/ux_design_bootcamp_2021.png"},
		{property: "og:url", content: "https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/"},
		{name: "twitter:card", content: "summary_large_image"},
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}
	script={[
		{ "src": "https://js.stripe.com/v3/" }
		]}
	/>



	<img 
	className='banners flex1200 offsetImage'
	src={UXBanner} 
	alt='UX/UI Design Online Mentored Bootcamp in Barcelona Code School'/>
	

	<h1 className='transparentContainer flex450'><span className='highlighter'>UX/UI Design Online 1:1 Mentored Bootcamp</span> in Barcelona Code School</h1>

	<h2 className='transparentContainer flex450'>Learn User Experience and User Interface Design by completing a real life project!</h2>

	
	<div className='transparentContainer flex300'>
	<h2>Tools</h2>
	<div className='whiteCard'>
	<UXlogos />
	</div>
	</div>

	<div className='transparentContainer flex600'>
	<h2>What is this course about</h2>
	<article className='whiteCard'>
	<ul className='neonList'>
	<li>Learn the <span className='highlighter'>whole UX Design process</span> from the idea to the prototype. We will guide you through all the steps of the UX and UI Design process applied on the project. Not only you will understand the theory and science behind UX Design but also will have an experience of implementing it with supervision from our instructors.</li>
	<li>Fully <span className='highlighter'>online 1:1 mentored hands-on</span> training</li>
	<li>Learn at your own pace and <span className='highlighter'>complete tasks to progress through the curriculum</span></li>
	<li>A completed project in the end</li>
	<li>Documenting the whole process</li>
	<li>Using industry standards aplicable at the real world</li>
	<li>Be guided by your instructor through all the steps</li>
	<li>The course is taught in <span className='highlighter'>English</span></li>
	<li>A <strong className='highlighter'>graduate certificate</strong></li>
	</ul>
	</article>
	</div>


	<div className='transparentContainer flex600'>
	<h2>How</h2>
	<article className='whiteCard'>
	<ol className='neonList'>
	<li>Register for the course from this page.</li>
	<li>Get the list of recommended pre-course materials to read before starting.</li>
	<li>Access our workspaces to connect with your instructor.</li>
	<li>Set up your first 1:1 video session with your mentor.</li>
	<li>Together with your instructor define the project you are going to work on through the course.</li>
	<li>Get all the main course materials.</li>
	<li>Study the materials for the first step of "Problem Statement". See examples of deliverables for this step. Create and submit your work for this step.</li>
	<li>Set up a 1:1 session to get your submission reviewd and recieve feedback from your instructor</li>
	<li>Iterate over your deliverables, submit the final version.</li>
	<li>Proceed to the next step with the same workflow.</li>
	<li>There are about 25 phases with the final one being your prototype.</li>
	<li>After the final 1:1 session the course is complete!</li>
	<li>You have your project done and all the steps of the UX Design process learned.</li>
	<li>Graduate and get a certificate!</li>
	</ol>
	</article>
	</div>

	<div className='transparentContainer flex600'>
	<h2>Why</h2>
	<article className='whiteCard'>
	<h3>Who will benefit most from this course?</h3>
	<p>This course is no-nonsense practical way of learning by doing. It covers all the steps of UX Design process on a real project which you will choose together with your mentor.</p>
	<p>In the end you will have a finished prototype with complete case study. While it can be a good starting point for students interested in launching their UX Designers career we see it especially beneficial for students willing or needing to learn UX as a secondary skill for their current proffesion.</p>
	<p>For example:</p>
	<ul className='neonList'>
	<li><span className='highlighter'>Developers</span> willing to understand how to do research and plannign of their apps before they will be coded.</li>
	<li><span className='highlighter'>Project Managers</span> and <span className='highlighter'>Product Owners</span> willing to understand this crucial stage of making a product.</li>
	<li><span className='highlighter'>UI Designers</span> and <span className='highlighter'>Web Designers</span> after taking this course will be able to make more informed decisions at their daily work.</li>
	<li>It will help <span className='highlighter'>Founders</span> to do crucial research and save money before starting the production</li>
	</ul>
	</article>
	</div>



	<div className="transparentContainer">
	<h4>Share on social media</h4>
	<div className="socialsharebuttons">
	<div className="socialshare" style={{'marginLeft':0}}>
	<a
	className="twitter-share-button "
	href="https://twitter.com/intent/tweet?text='UX/UI Design Bootcamp in Barcelona Code School'&url=https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/"
	data-size="large"
	>
	<button>tweet</button>
	</a>
	</div>

	<div
	className="fb-share-button socialshare"
	data-href="https://developers.facebook.com/docs/plugins/"
	data-layout="button"
	data-size="small"
	>
	<a
	target="_blank"
	href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/"
	className="fb-xfbml-parse-ignore"
	>
	<button>facebook</button>
	</a>
	</div>
	</div>
	</div>


{/*	<article className='transparentContainer flex450'>
	<h2>Get the syllabus</h2>
	<div className='whiteCard'>
	<p>Subscribe to our mailing list and get the syllabus now.</p>
	<ReqCur course="UX/UI Design Bootcamp" link="https://barcelonacodeschool.com/files/UX_UI_Design_Bootcamp_Syllabus_Full_Time.pdf" courselink="https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/"/>
	</div>
	</article>*/}

	<article className='transparentContainer flex450'>
	<h2>Tuition</h2>
	<div className='whiteCard'>
	<h2>2900€</h2>
	<p>One-time payment to register for the course</p>
	<Instalments/>
	</div>
	</article>

	<article className="transparentContainer flex600">
	<h2 id="cohorts">Access<span id="dates" style={{position: 'relative', top: '-50', visibility: 'hidden'}}>.</span></h2>
	<div className='flexContent'>
		{/*<Covid course={'jsinperson'}/>*/}
	<div className="whiteCard bottomSpace flex300" >
			<span>
			<h3>{course.title}</h3>
			<p>{course.shortdesc}</p>
			</span>
			<span>
			<p>{course.fulldesc1}</p>
			<p>{course.fulldesc2}</p>
			{/*<p>{date.includes('14')&&"Includes a Christmas break December 23 – January 2"}</p>*/}
			{/*<p className='highlighter'>{date.includes('14')&&<strong>Group is full</strong>}</p>*/}
			<Link className='buttonLink' to="/register" state={{course: course.title, date:(new Date()).toDateString().slice(4), cost:course.signupprice, images:[course.image]}}>
			<button style={{'marginBottom': '1em'}}>Sign up and start the course</button>
			</Link>
		{/*<p className='finePrint'>By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.</p>*/}
			</span>
			</div>
	</div>
	</article>

	<div className='transparentContainer flexContent flex900'>
	<h2>Students cases</h2>
	<article className='whiteCard flex300'>
	<h3>Akira, a developer</h3>
	<p>Akira started working as a developer in a small tech company. She is responsible for implementing new features to the existing projects and also for building projects from scratch.</p>
	<p>The team is small and they do not have a dedicated designers so Akira has to make some design choices as well. Her manager is also trying to participate and they constantly argue about design. Problem is that non of them have a professional knowledge about it and they follow their instinct and feeling.</p>
	<p>By taking this course Akira, as well as her manager, both can get enough knowledge to make informative decisions about UX and UI design without switching their jobs to full-time designers.</p>
	</article>
	<article className='whiteCard flex300'>
	<h3>Scott, a founder</h3>
	<p>After working as data analyst in a financial company Scott came up with an idea for the intra-company app which can facilitate the process of project management between developers and analytics.</p> 
	<p>He already has a background in programming and knows how to build this app technically. He also has real users and intuitively udnerstand their needs but he is curious how to conduct the UX research properly to gather all the information he needs and structure it befgore starting to code the app.</p>
	<p>By doing this course he can learn about the methods and techniques of oding research and use this information to properly plan his app so it will not flop.</p>
	</article>
		<article className='whiteCard flex300'>
	<h3>Laura, a project manager</h3>
	<p>Laura works in an agency managing designers and developers for the client's projects. She really loves her job and enjoys working with her team, While process of develoment is very technical and misterious to her she enjoys talking to the UX and UI Designers and she wants to understand better the process to provide more meaningful input into it and translate the needs of the clients better to her team.</p> 
	<p>By taking this course she will have a better understanding of all the stages of the UX, what they mean and what they do so she can participate more actively in the design team sprints and become a more valuable team member.</p>
	</article>
	</div>



{/*	<article className="transparentContainer flex900">
	<h2>Topics by week</h2>
	<div>
	<div className='weeks whiteCard'>
	<h3>Week 1: Design Sprint 2.0 <span onClick={(e)=>showFull(e)} title='week1' className={weeks.week1 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
	<div className='weekContent' title='week1ful' style={{display:weeks.week1}}>
	<ul className='neonList'>
	<li>Introduction to the core principles of user-centered design</li>
	<li>Design Sprint project process</li>
	<li>Contrast the feasibility of the project and defining the MVP</li>
	</ul>

	<p><strong>Primary activity</strong>: First week - ﬁrst project! We will introduce you to the core principles through a practical project using one of the most emerging methodologies - Google Design Sprints.</p>
	<p><strong>Project</strong>: Design Sprint Project - problem identiﬁcation, solution development, prototyping and market feasibility.</p>
	<p><strong>Tools</strong>: Design Sprint, Figma</p>
	</div>
	</div>

	<div className='weeks whiteCard'>
	<h3>Week 2: Introduction to UX Design and Group Project briefing <span onClick={(e)=>showFull(e)} title='week2' className={weeks.week2 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
	<div className='weekContent' title='week2ful' style={{display:weeks.week2}}>

	<ul className='neonList'>
	<li>Group project brieﬁng</li>
	<li>Heuristic Analysis</li>
	<li>Market and Trends Research</li>
	<li>Benchmark and Competitor Analysis</li>
	<li>UI challenges</li>
	</ul>

	<p><strong>Primary activity</strong>: You are going to discover your group project through the brieﬁng process and start analysing it. During this exercise you will analyse the current market situation to identify business possibilities in the sector.</p>
	<p><strong>Projects</strong>: This week you will start 2 projects - App Design Challenges on your own and a Group Project.</p>
	<p><strong>Tools</strong>: Figma</p>
	</div>
	</div>

	<div className='weeks whiteCard'>
	<h3>Week 3: User Research – techniques, analysis, and synthesis <span onClick={(e)=>showFull(e)} title='week3' className={weeks.week3 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
	<div className='weekContent' title='week3ful' style={{display:weeks.week3}}>
	<ul className='neonList'>
	<li>User Research: Quantitative vs. Qualitative</li>
	<li>Competitive Research</li>
	<li>Interviews & Surveys</li>
	<li>Research analysis: Persona, Scenarios, Journey and Empathy maps</li>
	<li>Gamestorming, Ideation, Workshop leadership</li>
	</ul>

	<p><strong>Primary activity</strong>: At this phase you will plan and conduct user research to understand behaviour, needs, and motivations of your users. You will learn the major methodologies to be able to understand the market and its users.</p>
	<p><strong>Projects</strong>: UX writing challenges and you’ll keep working on the group project applying the methodologies learned.</p>
	<p><strong>Tools</strong>: Notion, Optimal Workshop, Google Surveys</p>
	</div></div>

	<div className='weeks whiteCard'>
	<h3>Week 4: Ideation and Interaction Design Part 1 – Information Architecture and Wireframing <span onClick={(e)=>showFull(e)} title='week4' className={weeks.week4 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
	<div className='weekContent' title='week4ful' style={{display:weeks.week4}}>
	<ul className='neonList'>
	<li>Business Model Canvas</li>
	<li>Value proposition</li>
	<li>Agile and Lean UX</li>
	<li>Information Architecture</li>
	<li>Navigation</li>
	<li>Task Flows & Site Maps & Funnelling</li>
	<li>Sketching & Wireframes</li>
	</ul>

	<p><strong>Primary activity</strong>: You will learn how to translate your core ﬁndings from previous weeks into relevant business ideas and project requirements. You will also learn how to organise big chunks of information, create task ﬂows and a structure for your app/web.</p>
	<p><strong>Projects</strong>: Rapid prototyping with MarvelApp and continuing working on the group project applying the methodologies learned.</p>
	<p><strong>Tools</strong>: MarvelApp, Miro</p>
	</div></div>

	<div className='weeks whiteCard'>
	<h3>Week 5: Interaction Design Part 2 – UI & Prototyping <span onClick={(e)=>showFull(e)} title='week5' className={weeks.week5 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
	<div className='weekContent' title='week5ful' style={{display:weeks.week5}}>
	<ul className='neonList'>
	<li>Further Sketching & Wireframing</li>
	<li>From wireframes to a functional prototype</li>
	<li>Layout, composition, and elements</li>
	<li>Tools pros and cons for each project type</li>
	<li>Typography and Iconography</li>
	<li>Grids and Spacing</li>
	<li>UI Design for Responsive, Adaptive and Native applications</li>
	</ul>

	<p><strong>Primary activity</strong>: Roll up your sleeves! During this week you will prototype your idea and learn how to use some of the relevant market tools for prototyping.</p>
	<p><strong>Tools</strong>: Figma, Miro, Notion + some other UI tools such as Adobe XD, Invision or Sketch</p>
	</div></div>

	<div className='weeks whiteCard'>
	<h3>Week 6: Usability – Testing planning & Methodologies <span onClick={(e)=>showFull(e)} title='week6' className={weeks.week6 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
	<div className='weekContent' title='week6ful' style={{display:weeks.week6}}>
	<ul className='neonList'>
	<li>Plan a Validation</li>
	<li>Validation Methodologies with and without users</li>
	<li>Expert Reviews</li>
	<li>Accessibility</li>
	</ul>

	<p><strong>Primary activity</strong>: What do your users think? You will conduct a usability heuristic evaluation of your digital product, identify and apply necessary improvements based on users feedback.</p>
	<p>At this point you are going to present the Group project with your team in front of the clients (invited jury).</p>
	<p><strong>Tools</strong>: User test tools, Zoom</p>
	</div></div>

	<div className='weeks whiteCard'>
	<h3>Week 7: Personal project & Data visualization <span onClick={(e)=>showFull(e)} title='week7' className={weeks.week7 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
	<div className='weekContent' title='week7ful' style={{display:weeks.week7}}>
	<ul className='neonList'>
	<li>Data visualization</li>
	<li>MVPs</li>
	</ul>

	<p><strong>Primary activity</strong>: We’ll focus on the ideation and the scope of your idea to start deﬁning what’s gonna be your ﬁrst individual UX project.</p> 
	<p><strong>Projects</strong>: This week you will start your Personal project based on your ideas! It’s important to focus on something feasible as we will strongly work with the market contrast and you’ll come up with a super deﬁned MVP.</p>
	</div></div>

	<div className='weeks whiteCard'>
	<h3>Week 8: Personal project & Portfolio <span onClick={(e)=>showFull(e)} title='week8' className={weeks.week8 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
	<div className='weekContent' title='week8ful' style={{display:weeks.week8}}>
	<ul className='neonList'>
	<li>Communication with relevant roles and other soft skills</li>
	<li>Portfolio creation</li>
	<li>Identiﬁcation of your professional strengths for a future job</li>
	<li>LinkedIn & other tools</li>
	<li>Job seekers</li>
	</ul>

	<p><strong>Primary activity</strong>: You will learn extra skills such as basics of the programming that are valued in the market. Our instructors will give you guidance on making a perfect portfolio, highlighting your strengths and presenting yourself.</p> 
	</div></div>

	<div className='weeks whiteCard'>
	<h3>Week 9: Personal project presentation and deliverables <span   onClick={(e)=>showFull(e)} title='week9' className={weeks.week9 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
	<div className='weekContent' title='week9ful' style={{display:weeks.week9}}>
	<ul className='neonList'>
	<li>Finish and polish your project </li>
	<li>Deliverables creation</li>
	<li>The final presentation of your digital product</li>
	</ul>

	<p><strong>Final activity & Projects</strong>: Present your Final Project and get feedback from the professionals from the field who would be attending the final presentation day.</p>
	</div>

	</div>
	</div>
	</article>*/}


	<article className="transparentContainer flexContent flex1200">
	<h2>What our students say</h2>
	<div className='whiteCard flex225'>
	<p><strong>"Great intensive course!</strong> Good balance between theory lessons and projects. The small group is perfect to get personal assistance and resolving any doubt. The teachers are very good at adjusting their lessons to students' backgrounds and skills. Also, there are enough projects and content to put a portfolio together at the end of the Bootcamp."</p>
	<p className="author">— Mateo Pardo</p>
	</div>
	<div className='whiteCard flex225'>
	<p>"Studying process was very intense and full of knowledge. I think it <strong>exceeded all my expectations</strong>. Our professional teachers touched every possible topic during 9 weeks of a boot camp. They introduced us to the world of UX/UI, different platforms, tools and motivation videos. Our days were not like routine learning in a boring school, on the contrary, it was fun, easy and educational."</p>
	<p className="author">— Polina</p>
	</div>
	<div className='whiteCard flex225'>
	<p>"The course was a lot of fun to do! Absolutely worth it if you are looking to experience and learn UI/UX design. Instead of spending too much time on theory we jumped right on getting practical. Marina Tudela is friendly and explains everything very clear. She teaches you how to really <strong>get inside the head</strong> of the user/customer. Muchissimas gracias Marina!"</p>
	<p className="author">— R da Silva</p>
	</div>
	<div className='whiteCard flex225'>
	<p>"Very good UX course that helped me be sure that I want to activate in this field. I liked the overall environment and things we learnt."</p>
	<p className="author">— Vasilica Perianu</p>
	</div>
	</article>

	<UXProjectsExamples/>

{/*
	<article className='transparentContainer flex300'>
	<h2>The outcomes</h2>
	<div className='whiteCard'>

	<ul className='neonList'>
	<li>You will be able to <span className='highlighter'>design interactive products</span> that are easy to use, user-centered and that people love.</li>
	<li>You will learn to design great experiences for real products that can improve everyone’s life.</li>
	<li>If you are an entrepreneur, you will gain the necessary skills to <span className='highlighter'>bring your product to the next level</span> by building the best experience for your customers.</li>
	<li>If you are a developer, product manager, QA or any other field that requires direct contact with users you will learn the necessary skills to improve your seniority level and make your position more valuable.</li>
	</ul>
	</div>
	</article>*/}


{/*			<div className='uxPortfolios'>
			<img className='banners' src={Mateo} alt="UX Designer portfolio, Mateo Pardo"/>
			<a href='https://www.bretthalperin.com/' target='blank'>
			<img className='banners' src={Brett} alt="UX Designer portfolio, Brett Halperin"/>
			</a>
		</div>*/}





{/*	<h2>Main topics</h2>
	<ul className='neonList'>
	<li>Introduction to UX & Human-centred Design</li>
	<li>User Research</li>
	<li>Personas</li>
	<li>User Stories</li>
	<li>UX / UI Design</li>
	<li>Concept Map</li>
	<li>Flow Diagrams</li>
	<li>Wireframes</li>
	<li>Visual fundamentals</li>
	<li>Prototyping</li>
	<li>Testing & Validation</li>
	<li>Final Project</li>
</ul>*/}






	<article className='flex450'>
	<div className="video-container offsetImage" style={{marginBottom: '1em'}}>
	<iframe width="912" height="538" src="https://www.youtube.com/embed/ql8aKWYfDOQ" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen modestbranding='1'></iframe>
	</div>
	</article>


	<article className='transparentContainer flex300'>
	<h2>Why UX</h2>
	<div className='whiteCard'>
	<p>In today’s digital world people’s experiences have become a crucial aspect for every product, company or
	organization. The transversality of the UX field affects all areas of business as well as the technology and its
	development.</p>
	<p>This bootcamp will help you know your users better, design products that are truly functional and that
	people love, learn the proper design process and provide designs for real life problems.</p>
	</div>
	</article>

	<article className='transparentContainer flex300'>
	<h2>But I don’t know anything about UX...</h2>
	<div className='whiteCard'>
	<p>Our course is prepared for people with no previous experience in UX Design. Previous experience with related fields such as project or product management, visual or graphic design, development and QA will help you to better understand the industry, but it’s also ok if you want to start from scratch.</p>
	<p>Our instructors will not just teach you all the methodologies but also mentor you through the process of becoming a UX designer. With small groups and best students/teachers ratio, you will have a personalized approach for helping you to succeed. All you need to have is a laptop and a strong desire to become a UX designer.</p>
	<p>The rest is our job.</p>
	</div>
	</article>

	<article className='transparentContainer flex300'>
	<h2>What if I already know something about design?</h2>
	<div className='whiteCard'>
	<p>That’s great! Traditional Design skills (Graphic, Editorial, Digital…) are extremely useful for UX Designers.
	That will help you establish yourself in a higher seniority level once you finish the Bootcamp. We have mindbending
	challenges and more complex concepts to consolidate, strengthen and improve further your skills!</p>
	</div>
	</article>





	<article className='transparentContainer flex300'>
	<h2>Financing</h2>
	<div className='whiteCard'>
	<p>We can assist in getting a student loan which usually have lower rates than consumer loans.</p>
	<p>To do so we can send you a proforma invoice for the selected course which you can attach to the student's loan application.</p>
	<p>Please <a href="https://docs.google.com/forms/d/e/1FAIpQLSeIeOLI2k3DlSQmwdUFPMawFr7-LUVWEnd2Huy4mMaRu0bncg/viewform" target="_blank">fill out this form</a>.</p>
	<Instalments/>
	</div>
	</article>







	<div className='transparentContainer flex450'>
	<article className='whiteCard'>
	<h2>FAQ</h2>
	<div>
	<details> <summary><strong>What computer would I need?</strong></summary><p>It can be Mac or Windows.</p>

	</details> 
	<details> <summary><strong>What is the language of this course?</strong></summary><p>English.</p>

	</details> 

	<details> <summary><strong>Do I have to speak Spanish?</strong></summary><p>For the course you do not need Spanish. Everybody at our school speak English.</p>

	</details> 

	<details> <summary><strong>Who is this course for?</strong></summary><p>Beginners. If you don't know what UX design is – this course is for you. If you know what Lean UX is – even better. If you can explain difference between a wireframe and a prototype you will be doing just fine.</p>

	</details> 

	<details> <summary><strong>What if I already know how to do some UX or UI Design?</strong></summary><p>Amazing! For students who advance faster or have some prior experience we have more challenging track with hardcore exercises and job opportunities upon graduation.</p>

	</details> 



	<details> <summary><strong>Is there a pre-course?</strong></summary><p>Yes. We send you materials to study to come prepared!</p>

	</details> 

	<details> <summary><strong>How does your price compare to other schools?</strong></summary><p>It’s safe to say that our price is within lower competitive range. We strive to provide the personalized approach thanks to smaller group size and attract highly qualified instructors motivated to share their expertise with students. You will love it, we guarantee!</p>
	</details>

	</div>
	</article>
	</div>


	<div className='flex450'>
	<Contacts
	title={"Inquiry about the course"}
	subject={"Inquiry about UX/UI Design Online Mentored Bootcamp"}
	/>
	</div>





	<article className='transparentContainer flex900'>
	<h2>Some of the companies where our graduates work or worked</h2>
	<div>
	<PartnersLogos />
	</div>
	</article>

	</div>
} 


export default UXBootcamp
